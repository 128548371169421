<template>
  <div>
    <!--    组织单位-->
    <div>
      <el-select v-model="meetdw" class="m-2" placeholder="请选择召开单位" size="mini"
                 style="margin-left: 30px;margin-right: 40px" @change="getMeetMainMessage()">
        <el-option
            v-for="item in meetdws"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </div>
    <!--    会议数据-->
    <div style="margin-left: 30px;margin-right: 30px;margin-top: 20px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="meetMessage"
          size="small"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"

      >
        <vxe-column field="startunit" title="组织单位" width="10%"></vxe-column>
        <vxe-column field="meetname" title="会议名称"></vxe-column>
        <vxe-column field="meetime" title="会议时间"></vxe-column>
        <vxe-column field="a" title="是否召开">
          <template #default="{ row }">
            <el-tag type="danger" v-if="row.isend === '1'">已召开</el-tag>
            <el-tag type="success" v-if="row.isend === '0'">未召开</el-tag>
          </template>
        </vxe-column>
        <vxe-column title="操作" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getMeetDetail(row)">查看参会人员</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <!--    参会人员信息-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="参会人员"
          width="60%"
      >
        <div style="width: 100%;word-wrap:break-word;">
          <el-button v-for="(item, index) in meetusers" type="warning" size="mini" style="margin-top: 5%">{{ item.meetuser }}</el-button>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">关闭</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

  </div>
</template>

<script>
export default {
  name: "manage_meet",
  data() {
    return {
      meetdw: '',
      meetdws: [
        {
          value: '集团公司',
          label: '集团公司',
        },
        {
          value: '股份公司',
          label: '股份公司',
        },
        {
          value: '项目公司',
          label: '项目公司',
        },
        {
          value: '康定项目部',
          label: '康定项目部',
        },
        {
          value: '四川公司',
          label: '四川公司',
        },
        {
          value: '川藏公司',
          label: '川藏公司',
        },
        {
          value: '川藏股指',
          label: '川藏股指',
        },
      ],
      meetMessage: [],
      allAlign: 'center',
      dialogVisible: false,
      meetmainid: '',
      meetusers: []
    }
  },
  created() {
    this.getMeetMainMessage();
  },
  methods: {
    getMeetMainMessage() {
      this.axios.post('/meetMain/getMeetMainMessage', (response) => {
        this.meetMessage = response.obj;
        console.log(this.meetMessage)
      }, {
        startunit: this.meetdw
      })
    },
    getMeetDetail(row) {
      console.log(row)
      this.dialogVisible = true;
      this.axios.post('/meetBranch/getMeetUserByMainId', (response) => {
        this.meetusers = response.obj
      },{
        meetmainid: row.id
      })
    }
  }
}
</script>

<style scoped>

</style>